import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "base-input__label"
}
const _hoisted_2 = {
  key: 0,
  class: "base-input__prefix"
}
const _hoisted_3 = ["placeholder", "autofocus", "disabled", "required", "type"]
const _hoisted_4 = { class: "base-input__error-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input", { 'base-input_light': _ctx.light }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'base-input__wrapper_focused': _ctx.focused,
        'base-input__wrapper--error': _ctx.getErrors.length,
      }, "base-input__wrapper"])
    }, [
      (_ctx.prefix)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.prefix), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        placeholder: _ctx.placeholder,
        autofocus: _ctx.autofocus,
        disabled: _ctx.disabled,
        required: _ctx.required,
        type: _ctx.inputType,
        class: _normalizeClass([{
          'base-input__input_no-left-padding': _ctx.prefix,
          'base-input__input--error': _ctx.getErrors.length,
        }, "base-input__input"]),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = false))
      }, null, 42, _hoisted_3), [
        [_vModelDynamic, _ctx.inputValue]
      ])
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getErrors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        key: error.type
      }, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(error.text), 1)
      ]))
    }), 128))
  ], 2))
}