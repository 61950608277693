import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLoader = _resolveComponent("BaseLoader")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.htmlType,
    class: _normalizeClass([{
      'base-button_primary': _ctx.type === 'primary',
      'base-button_danger': _ctx.type === 'danger',
      'base-button_filled': _ctx.filled,
      'base-button_disabled': _ctx.isButtonDisabled,
      block: _ctx.block,
      'base-button-small': _ctx.small,
    }, "base-button"]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    disabled: _ctx.isButtonDisabled
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _withDirectives(_createVNode(_component_BaseLoader, null, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ], 10, _hoisted_1))
}